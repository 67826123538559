define([
        'modules/upx/models/model',
        './structs/TranslatedShippingMethod'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShippingModule',

            object: 'TranslatedShippingMethod',
            newShippingMethodTranslatable: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newShippingMethodTranslatable',parameters, ajaxOptions);
            }
        });
    }
);