define([
        'modules/upx/collections/collection',
        '../models/ShipmentStatusType',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ShippingModule',
            object: 'ShipmentStatusType',
            collection_method: 'listShipmentStatusTypes',
            model: Model
        });
    }
);