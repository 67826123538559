define([
        'modules/upx/models/model',
        './structs/ShipmentStatusType'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShippingModule',

            object: 'ShipmentStatusType'
        });
    }
);