define([
        'modules/upx/models/model',
        './structs/Address'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'RelationsModule',
            idAttribute: 'id',
            object: 'Address',
            searchByZipcode: function (parameters, ajaxOptions) {
                return this.callObjectMethod('searchAddressByZipcode',parameters, ajaxOptions);
            }
        });
    }
);